import { useEffect } from 'react';

import type { RequestError } from '@/renderer/utils/errors';
import { usePageContext } from '@/renderer/utils/usePageContext';

export default ErrorPage;

function ErrorPage({
  is404,
}: // errorTitle,
// errorDescription,
{
  is404: boolean;
  errorTitle?: string;
  errorDescription?: string;
}) {
  const pageContext = usePageContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies(is404): ideally doesnt change
  useEffect(() => {
    try {
      if (is404 && typeof window !== 'undefined') {
        window.plausible?.('404', { props: { path: document.location.pathname } });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (is404 || ('is404' in pageContext && pageContext.is404)) {
    return (
      <div className="flex h-[100dvh] flex-col items-center justify-center space-y-4 text-center">
        <h1 className="text-6xl">404</h1>
        <p className="text-sm text-zinc-500">Sorry, the page you visited doesn't exist.</p>
        <a href="/">
          <button className="rounded-sm border-0 bg-emerald-500 px-3 py-1 text-sm text-white hover:bg-emerald-600">
            Return home
          </button>
        </a>
      </div>
    );
  }

  let code: '403' | '404' | '500' | '503' = '500';
  let title: string = code;
  let msg = 'Sorry, there seems to be an error with our servers. Please try again in one minute.';
  const err =
    ('errorWhileRendering' in pageContext &&
      (pageContext.errorWhileRendering as (Error | RequestError) & {
        _pageContextAbort: {
          _abortStatusCode: number;
          abortReason: string;
          abortReasonCode: number;
        };
      })) ||
    undefined;

  const abortStatusCode =
    ('abortStatusCode' in pageContext && pageContext.abortStatusCode) ||
    err?._pageContextAbort?._abortStatusCode;
  const abortReason =
    ('abortStatusReason' in pageContext && pageContext.abortReason) ||
    err?._pageContextAbort?.abortReason;
  const abortReasonCode: number | undefined =
    ('abortReasonCode' in pageContext && (pageContext.abortReasonCode as number)) ||
    err?._pageContextAbort?.abortReasonCode;
  const abortCode = abortStatusCode || abortReasonCode;

  // console.log('dang', pageContext, is404);

  if (abortCode) {
    if (abortCode == 503) {
      code = '503';
      title = code;
    }

    if (abortCode == 403) {
      code = '403';
      title = code;
    }

    if (abortReason) {
      msg = `${abortReason}`;
    }
  } else if ((err as RequestError)?.code == 503) {
    code = '503';
    msg = 'RAS is temporarily unavailable. Please try again in a minute.';

    if ((err as RequestError).data?.message) {
      title = (err as RequestError).data.message;
    }
  } else if ((err as RequestError)?.code == 401) {
    code = '403';
    msg = 'You are not authorized to access this. Try logging into your account.';

    if ((err as RequestError).data?.message) {
      title = (err as RequestError).data.message;
    }
  }

  return (
    <div className="flex h-[100dvh] flex-col items-center justify-center text-center">
      <div>
        <h1 className="text-6xl">{code}</h1>
        {!!title && <p className="text-sm text-zinc-500">{title}</p>}
        {!!msg && <p className="text-sm text-zinc-500">{msg}</p>}
        <a href="/">
          <button className="rounded-sm border-0 bg-emerald-500 px-3 py-1 text-sm text-white hover:bg-emerald-600">
            Return home
          </button>
        </a>{' '}
        <button
          onClick={() => location.reload()}
          className="rounded-sm border-0 bg-emerald-500 px-3 py-1 text-sm text-white hover:bg-emerald-600"
        >
          Reload
        </button>
      </div>
    </div>
  );
}
